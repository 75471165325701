@import '~@blueprintjs/core/lib/css/blueprint';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import './variables';

.app-wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
  color: $color-default-text-fg;

  flex-direction: column;
  flex: 1;
  align-items: stretch;

  > header {
    display: flex;
    align-items: center;
    background-color: $color-primary-bg;
    color: $color-primary-text-fg;
    padding: $p-mega;

    > img {
      width: 160px;
      padding-right: $p-mega;
      margin-right: $p-mega;
      border-right: 1px solid $color-primary-text-fg;
    }

    > h1 {
      font-weight: normal;
      font-size: 1.3rem;
    }
  }
  > footer {
    background: rgb(248, 249, 250);
    border-top: 1px solid rgb(233, 236, 239);
    padding: $p-mini $p-mega;
    color: $color-disabled-text-fg;
    font-size: 0.75rem;

    > ul {
      margin: $p-mega 0;
    }
  }
  > .content {
    flex: 1;
    display: flex;
    justify-content: center;

    > .form-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      > h6 {
        font-weight: normal;
      }

      > form {
        min-width: 480px;
        max-width: 600px;
        padding: $p-maxi;

        > h4 {
          margin-top: 4px;
        }

        &.bordered {
          box-shadow: 1px 1px 8px 0px #ccc;
        }


        .bp3-label { color: $color-lighter-text-fg; }
        .bp3-form-group {
          display: flex;
          justify-content: space-between;
        }
      }

      > form {
        position: relative;

        > .bp3-popover-wrapper {
          display: block;
          > .bp3-popover-target {
            width: 100%;
          }
        }

        > .bp3-overlay {
          display: flex;
          align-items: center;
          justify-content: center;

          > .bp3-overlay-backdrop {
            background-color: rgba(255, 255, 255, 0.75);
          }
          > .bp3-spinner {
            position: static;
          }
          > h5 {
            margin-top: 120px;
          }
        }
      }
    }
  }
}

.credits-distribution-wrap {
  margin-bottom: $p-maxi;

  small {
    color: $color-lighter-text-fg;

    &.danger-text {
      color: $color-danger-text-fg;
    }
  }

  > .bp3-callout {
    background-color: $color-callout-bg;
    color: $color-callout-fg;
    margin-bottom: $p-mega;
  }

  .apps-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: $p-mega * 3;

    > * {
      flex: 1;
      margin-bottom: $p-mega;
    }
  }
}

@media only screen and (max-width: 600px) {
  .app-wrap > header > h1,
  .app-wrap > .content > .form-wrap > h1 {
    font-size: 1rem;
    padding: 0 10px;
  }
  .app-wrap > .content > .form-wrap > form {
    min-width: 200px;
  }
}

.flex {
  display: flex;
  &.space-between { justify-content: space-between }
}
.inline-block {
  display: inline-block;
}

.zero-m { margin: 0; }


ul.horizontal {
  list-style-type: none;
  padding-inline-start: 0;

  > li {
    display: inline-block;
    margin: 0 $p-standard;
  }
}


.messaging-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .bp3-icon > svg {
    width: $messaging-icon-size;
    height: $messaging-icon-size;

    &.success {

    }
  }
}

.field-label-with-info.bp3-control-group {
  > label {
    margin-right: $p-mega !important;
  }
  > .bp3-popover-wrapper {
    margin-top: 2px;
  }
}

.disabled-text {
  color: $color-disabled-text-fg;
}
.danger-text {
  color: $color-danger-text-fg;
}
.hr-with-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $p-mega 0;
  > label {
    display: inline-block;
    margin-top: -($p-mega * 2);
    background: #fff;
    padding: 0 $p-mega
  }
  > hr {
    width: 100%;

  }
}

.privacy-message {
  display: block;
  font-size: 75%;
  margin-bottom: $p-standard;
  text-align: justify;
  > p {
    margin-bottom: $p-standard;
  }
  a {
    margin: 0 0 0 3px;
  }
}
