$p-mini: 2px;
$p-standard: $p-mini * 2;
$p-mega: $p-standard * 2;
$p-maxi: $p-mega * 2;


$color-primary-bg: #0373c7;
$color-secondary-bg: #f5f5f5;
$color-popup-bg: #22262a;
$color-popup-fg: #c6c6c7;
$color-icon-fg: #22262a;
$color-primary-text-fg: #f3f4f5;
$color-default-text-fg: #22262a;
$color-lighter-text-fg: #69727a;
$color-disabled-text-fg: #969ca2;
$color-danger-text-fg: #c12020;

$color-callout-bg: #f5eac6;
$color-callout-fg: #22262a;


$messaging-icon-size: 48px;
